@font-face {
  font-family: 'Winston';
  src: url('./Accessories/font/Winston-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Posto';
  src: url('./Accessories/font/PostNoBillsJaffna-Light.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');


html{
  width: 100vw;
  height: 100vh;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



////////////////////LOGIN
.loginWrapper{
  background-image: url('./Accessories/images/bg.jpg');
  min-width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  font-family: 'Open Sans', sans-serif;
}

.formWrapper{
  position: absolute;
  height: 500px;
  width: 400px;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  background-color: rgba(19, 41, 18, 0.54);
  border-radius: 20px;
}

.loginForma{
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  height: 300px;
  width: 250px;
}

.form-group{
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formaNaslov{
  color: wheat;
  font-size: 43px;
}

.formaUsername{
  width: 340px;
  height: 43px;
  border-radius: 20px;
  font-size: 23px;
  background-color:rgba(234, 237, 243, 0.1);
  border-color: transparent;
  font-family: 'Open Sans', sans-serif;
  padding-left: 20px;
  font-weight: bold;
  color: white;
}
.formaUsername::placeholder{
  color: wheat;
  padding-left: 5px;
  font-size: 20px;
}
.formaUsername:hover{
  border-color: white;
}

.formaSifra{
  width: 340px;
  height: 43px;
  font-weight: bold;
  font-size: 23px;
  border-radius: 20px;
  background-color:rgba(234, 237, 243, 0.1);
  border-color: transparent;
  font-family: 'Open Sans', sans-serif;
  padding-left: 20px;
  color: white;
}
.formaSifra::placeholder{
  color: wheat;
  padding-left: 5px;
  font-size: 20px;
}
.formaSifra:hover{
  border-color: white;
}

.eye {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.formaBtn{
  width: 340px;
  height: 40px;
  border-radius: 20px;
  background-color: rgb(24, 165, 24);
  border-color: transparent;
  color: white;
  font-size: 23px;
  font-family: 'Open Sans', sans-serif;
}
.formaBtn:hover{
  background-color: rgb(34, 139, 34);
}
.formaBtn:active{
  background-color: rgb(56, 185, 56);
}

.loginKontakt{
  color: white;
  font-size: 20px;
}

.kontaktBtns{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kontaktFB{
  flex-direction: row;
  width: 140px;
}

.kontaktEM{
  flex-direction: row;
  width: 140px;
}

.kontakt{
  width: 120px;
  height: 43px;
  border-color: transparent;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}
.kontakt:hover{
  background-color: #534141;
  color: white;
  transform: scale(1.1);
}



//////////////////////////////////////////////HOME

.wrapperHome{
  min-width: 100vw;
  min-height: 100vh;
  background-image: url('./Accessories/images/home.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.homeNaslov{
  color: black;
  text-align: center;
  font-family: 'Winston';
}

.rounded-lg {
  border-radius: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.custom-separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
}

.text-uppercase {
  letter-spacing: 0.2em;
}
.tabovi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tab {
  width: 100%;
  max-width: 270px;
  border: 1px solid;
  border-radius: 5%;
}

.link {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 17px;
}

/* Styles for the main container column */
.col-lg-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

/* Margin at the bottom (mb) for large screens (lg) */
.mb-lg-0 {
  margin-bottom: 0;
}

/* Margin at the bottom (mb) with a value of 5 units */
.mb-5 {
  margin-bottom: 1.25rem;
}

/* Horizontal margin (mx) with a value of 3 units */
.mx-3 {
  margin-right: 1rem;
  margin-left: 1rem;
}

/* Styles for the inner container */
.bg-white {
  background-color: #fff;
  height: 300px;
}

/* Padding on the X-axis (px) with a value of 3 units */
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

/* Padding on the Y-axis (pt and pb) with a value of 5 units and 2 units respectively */
.pt-5 {
  padding-top: 1.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

/* Rounded corners (rounded-lg) and shadow effect */
.rounded-lg {
  border-radius: 0.3rem;
}

.shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

/* Word wrapping for the overflowed content */
.style {
  overflow-wrap: break-word;
}

/* Styling for the heading */
.h6 {
  font-size: 1rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

/* Margin at the bottom (mb) with a value of 4 units */
.mb-4 {
  margin-bottom: 1rem;
}

/* Custom separator style with a primary background color */
.custom-separator {
  background-color: #06b;
  margin-left: auto;
  margin-right: auto;
}

/* Margin on the Y-axis (my) with a value of 5 units */
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.text-small {
  font-size: 0.875rem;
}

.text-left {
  text-align: left;
}

/* Styling for list items */
.font-weight-normal {
  font-weight: normal;
}

/* Margin at the bottom (mb) with a value of 3 units for list items */
li.mb-3 {
  margin-bottom: 1.35rem;
}

ul,
ol {
  list-style: none;
}

/* Media queries for responsiveness */

/* For screens smaller than 768px (e.g., smartphones) */
@media (max-width: 767px) {
  .tab {
    max-width: 100%;
  }
}

/* For screens between 768px and 991px (e.g., tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .tab {
    max-width: 50%;
  }
}

/* For screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1200px) {
  .tab {
    max-width: 33.3333%;
  }
}

.prva{
  background-image: url('./Accessories/images/farma.png');
  background-size: cover;
}

.druga{
  background-image: url('./Accessories/images/trade.png');
  background-size: cover;
}

.treca{
  background-image: url('./Accessories/images/storage.png');
  background-size: cover;
}

.cetvrta{
  background-image: url('./Accessories/images/masine.png');
  background-size: cover;
}









//////////////////////////////////////FARMA

.wrapperFarma{
  min-width: 100vw;
  min-height: 115vh;
  background-image: url('./Accessories/images/wallpaper.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -30px;
}


.filteri{
  border: 1px solid #525050;
  background-color: #7b7575;
  font-weight: bold;
  border-radius: 10px;
  width: 250px;
  min-width: 230px;
  height: 650px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filteriShort{
  border: 1px solid #525050;
  background-color: #7b7575;
  font-weight: bold;
  border-radius: 10px;
  width: 250px;
  min-width: 230px;
  height: 580px;
  margin-top: -63px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filteri-farma{
  border: 1px solid #525050;
  border-radius: 10px;
  width: 250px;
  min-width: 230px;
  height: 610px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filteriNaslov{
  margin-bottom: -8px;
  text-align: center;
}

.filteriNaslovBreak{
  margin-bottom: -20px;
  text-align: center;
}

.filteriNaslovImport{
  margin-bottom: 15px;
}


.search-container {
  position: relative;
  display: inline-block;
  align-items: center;
  margin-top: 20px;
  width: 70%;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  margin-top: 12px;
  padding: 3px;
  height: 11px;
  margin-left: 30px;
  background-color: white;
  transform: translateY(-50%);
}


.inputFilter{
  border-color: transparent;
  width: 123px;
  background-color: white;
  font-weight: bold;
}

.inputFilter:focus{
  outline: none;
}

.inputFilterEdit{
  border-color: transparent;
  width: 103px;
  margin-left: -32px;
  background-color: white;
  font-weight: bold;
}

.inputFilterEdit:focus{
  outline: none;
}

.textFilterWrap{
  background-color: white;
  width: 180px;
}
.radioFilteri{
  align-self: flex-start;
  margin-left: 30px;
}

.comboFilteri{
  align-self: flex-start;
  margin-left: 40px;
}

.farmaTabelaWrapper{
  margin-top: -65px;
  margin-left: 120px;
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.farmaTable {
  
  background-color: #b5aeae !important;
  color: #000 !important;
  overflow: auto;
  max-width: 920px;
  width: 918px;
  table-layout: fixed;
  border: 2px solid #000;
  text-align: center;
}

.farmaTable th,
.farmaTable td {
  border: 1px solid black !important;
}

.farmaTable td {
  border: 1px solid black !important;
}

.farmaTable th.freez {
  position: sticky;
  top: 0;
  z-index: 0;
  background-color: #867979;
}

.farmaTable td {
  border: 1px solid white;
}

.farmaDugmad {
  display: flex;
  flex-direction: row;
}
.farmaDugme{
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  width: 90px;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  margin: 5px;
  vertical-align: middle;
}

.farmaDugme:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.farmaDugme:hover {
  background-color: #2c974b;
}

.farmaDugme:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.farmaDugme:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.farmaDugme:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: spinner 1.5s linear infinite;
}


  .editButton {
    align-items: center;
    background-color: #703f3f;
    background-image: linear-gradient(#a59191, #966d6d);
    border-radius: 4px;
    width: 91px;
    border-width: 0;
    box-shadow: 0 10px 20px rgba(233, 227, 227, 0.1),0 3px 6px rgba(0, 0, 0, .05);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 16px;
    height: 23px;
    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: none;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .editButton:hover {
    box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
    opacity: .85;
  }
  
  .editButton:active {
    outline: 0;
  }
  
  .editButton:focus {
    box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
  }
  




  @media (max-width: 768px) {
    .wrapperFarma {
      flex-direction: column; /* Change flex-direction to column for smaller screens */
    }

    .wrapperMehanizacija {
      flex-direction: column; /* Change flex-direction to column for smaller screens */
    }
  
    .filteri {
      width: 100%; /* Make the filteri div occupy the full width */
      margin-top: 10px; /* Adjust the top margin */
    }
  
    .farmaTabelaWrapper {
      margin-left: 0; /* Reset the left margin for the table wrapper */
      width: 100%; /* Make the table wrapper occupy the full width */
      overflow-x: auto; /* Add horizontal scroll for small screens if needed */
    }
  
    .farmaTable {
      max-width: 100%; /* Make the table occupy the full width */
      width: auto; /* Allow the table to expand based on content */
    }
    
    /* Adjust styles for the search containers */
    .search-container {
      margin: 10px 0; /* Add some margin between search containers */
    }
  
    /* Add media query for even smaller screens */
    @media (max-width: 420px) {
      .editButton {
        height: 48px; 
      }
    }
  }










/////////////////////////////////////USEVI
.wrapperUsevi{
  min-width: 100vw;
  min-height: 125vh;
  background-image: url('./Accessories/images/wallpaper.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -43px;
}

.filteri-usevi{
  border: 1px solid #525050;
  background-color: #7b7575;
  font-weight: bold;
  border-radius: 10px;
  width: 250px;
  min-width: 230px;
  height: 730px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.datumi{
  margin-top: 20px;
  margin-bottom: -5px;
}
.react-datepicker-wrapper{
  margin-bottom: 10px;
}

.datepicker{
  width: 133px;
  height: 14px;
  padding-left: 34px !important;
}

.react-datepicker__close-icon::after {
  background-color: #000 !important;
  height: 10px !important;
  width: 10px !important;
}

.useviTabelaWrapper{
  margin-left: 120px;
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  margin-top: -105px;
}

.numberFilterWrap{
  background-color: white;
  width: 180px;
}

.numberInputFilter{
  border-color: transparent;
  width: 163px;
  background-color: white;
  font-weight: bold;
}

.numberInputFilter:focus{
  outline:none
}



@media (max-width: 768px) {
  .wrapperUsevi {
    flex-direction: column; /* Change flex-direction to column for smaller screens */
    min-height: 160vh; /* Increase the minimum height to accommodate the content */
  }

  .filteri-usevi {
    width: 100%; /* Make the filteri-usevi div occupy the full width */
    height: auto; /* Adjust the height automatically based on content */
    margin-top: 30px; /* Adjust the top margin */
  }

  .useviTabelaWrapper {
    margin-left: 0; /* Reset the left margin for the table wrapper */
    margin-top: -10px; /* Adjust the top margin */
  }

  .numberFilterWrap {
    width: 100%; /* Make the number filter wrapper occupy the full width */
  }

  .numberInputFilter {
    width: 100%; /* Make the number input occupy the full width */
  }

  .datepicker {
    width: 100%; /* Make the datepicker occupy the full width */
    padding-left: 10px !important; /* Adjust the padding-left for the datepicker */
  }

  .react-datepicker__close-icon::after {
    height: 8px !important; /* Adjust the height of the close icon */
    width: 8px !important; /* Adjust the width of the close icon */
  }
}
























///////////////////////////////////POPUP  -----------------------------------

.PopUpWrapper{
  background-color: #929292;
  margin-top: -65px;
  border-radius: 15px;
}

.PopUpTableWrapper{
  overflow-y: auto;
  height: 570px;
  max-height: 570px;
  margin-top: 15px;  
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PopUpTable{
  table-layout: fixed;
  background-color: transparent;
  border: 2px solid #61dafb;
  color: black;
  text-align: center;
  min-width: 650px;
  overflow: auto;
}

.PopUpTable th,
.PopUpTable td {
  border: 1px solid white;
}

.PopUpTable tr:hover {
  background-color: #6284c0;
}

.PopUpTable td {
  border: 1px solid white;
}

.PopUpTable thead tr:hover {
  background-color: transparent !important;
}

.PopUpTitle{
  padding: 5px;
  padding-left: 30px;
  width: 685px;
  background-color: rgb(19, 99, 55);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
/* WebKit (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #d55959;
}

/* Firefox */
/* Note: Firefox only supports styling the scrollbar thumb */
* {
  scrollbar-width: thin;
  scrollbar-color: #d55959 #e7e7e7;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

*::-webkit-scrollbar-thumb {
  background-color: #d55959;
  border: 3px solid transparent;
  background-clip: content-box;
}



.inputPopUpWrapper{
  background-color: #929292;
  margin-top: -65px;
  border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.popUpFilteri {
  border: 1px solid #525050;
  border-radius: 10px;
  width: 250px;
  min-width: 230px;
  height: 560px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}




.PopUpWrapperChild{
  background-color: #179661;
  margin-top: -75px;
  border-radius: 15px;
}


.PopUpTitleChild{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}


.PopUpTableWrapperChild{
  overflow-y: auto;
  height: 500px;
  max-height: 500px;
  margin-top: 15px;  
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeChild{
  background-color: rgb(85, 153, 209);
  border-color: transparent;
  font-weight: bold;
  border-radius: 50%;
  font-size: 20px;
  margin-right: 10px;
}

.closeChild:hover{
  background-color: rgb(14, 58, 94);
}


.naslovnaTraka{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 620px;
  height: 40px;
  padding-left: 30px;
  background-color: rgb(19, 99, 55);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}






















//////////////////MEHANIZACIJA
 .wrapperMehanizacija{
  min-width: 100vw;
  min-height: 115vh;
  background-image: url('./Accessories/images/meh.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -30px;
}

.wrapperLager{
  min-width: 100vw;
  min-height: 115vh;
  background-image: url('./Accessories/images/silos.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -30px;
}

.overOneYear{
  background-color: red;
}




















//UNOS U TABELU    ---------------------------------------------------------------------------------------------------

.wrapperTabela {
  background-color: #000;
  display: flex;
  min-height: 100vh; /* Set the minimum height to fill the viewport */
  width: 100vw;
  flex-direction: row;
  position: relative;
  height: 100%;
}


.form {
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height: 540px;
  padding: 20px;
  width: 320px;
  margin-top: 55px;
  margin-left: 55px;
}

.title {
  color: #eee;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}
.input:focus{
  background-color: #50536f;
}
.cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.submit {
  background-color: rgb(7, 107, 170);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  // outline: 0;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #06b;
}






//SIDEMENU     -------------------------------------------------------------------------------

.hamburger-menu{
  z-index: 999;
}
#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}
.menu__btn {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 60px 0;
  list-style: none;
  background-color: #3c914b;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transition-duration: .25s;
}
.menu__item {
  display: block;
  padding: 10px 24px;
  color: rgb(255, 255, 255);
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none !important; 
  transition-duration: .25s;
}
.menu__item:hover {
  background-color: rgb(102, 157, 240);
  color: black !important;
}

@media (max-width: 768px) {
  .menu__btn {
    top: 10px; /* Adjust the top position for the menu button */
    left: 10px; /* Adjust the left position for the menu button */
    width: 20px; /* Reduce the width of the menu button */
    height: 20px; /* Reduce the height of the menu button */
  }

  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    height: 1px; /* Reduce the height of the lines in the menu button */
  }

  .menu__btn > span::before {
    top: -6px; /* Adjust the top position of the top line in the menu button */
  }

  .menu__btn > span::after {
    top: 6px; /* Adjust the top position of the bottom line in the menu button */
  }

  .menu__box {
    width: 100%; /* Make the menu box occupy the full width */
    padding: 60px 0; /* Reduce the top padding of the menu box */
  }

  .menu__item {
    padding: 10px 20px; /* Reduce the padding of the menu items */
    font-size: 18px; /* Reduce the font size of the menu items */
  }
}















//GRAFIK   ---------------------------------------------------------------------------------

.graphPage{
  background-color: #000;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  
}
.graphDugme{
  width:120px;
  height: 43px;
  background-color: rgb(7, 107, 170);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  margin: 10px;
  // outline: 0;
  text-align: center;
}

.trakaDugmad{
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontalnaTraka{
  display: flex;
  flex-direction: row;
}

#myChart{
  height: 500px;
  display: flex;
  margin: 43px;
  margin-top: 2px;
  width: 67vw;
  margin-right: 80px;
}

.formaGraph{
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
  margin-top: -20px;
  height: fit-content;
  width: 320px;
}

.formaDugmad{
  max-width: 310px;
  max-height: 60px;
  justify-content: center;
  align-items: center;
}

.b1 {
  background-color: rgb(7, 107, 170);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 43px;
  // outline: 0;
  text-align: center;
  width: 28%;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 6px;
  margin-right: 6px;
}

.cut-short-graph {
  width: 135px;
}
///////////////////////////////////

/* Styles for mobile devices */
@media screen and (max-width: 575px) {
  .wrapperTabelaApi,
  .wrapperTabela {
    display: flex;
    min-height: 350vh;
    width: 350%;
    flex-direction: row;
    position: relative;
    margin-top: 1000px;
}

.graphPage{
  min-height: 350vh;
  width: 350%;
}
#myChart{
  width: 700px;
}
.horizontalnaTraka{
  margin-top: 30px;
}
.stranicaTabelaTabela{
  margin-right: 40px;
}
}

/* Styles for tablets */
@media screen and (min-width: 576px) and (max-width: 1160px) {
  .wrapperTabelaApi,
  .wrapperTabela {
    display: flex;
    min-height: 150vh;
    min-width: 200vw;
    width: 120%;
    flex-direction: row;
    position: relative;
    
  background-size: cover;
  background-repeat: no-repeat;
}


.graphPage{
  min-height: 350vh;
  width: 350%;
}
#myChart{
  width: 1500px;
}
.horizontalnaTraka{
  margin-top: 30px;
}
}

/* Styles for desktop and larger screens */
@media screen and (min-width: 1225px) {
  
  .wrapperTabela {
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: row;
    position: relative;
}



}